/* External Packages */
import './scripts/jqueryLib.js'
import 'foundation-sites/dist/js/plugins/foundation.core.js';
import 'foundation-sites/dist/js/plugins/foundation.abide.js';
import 'slick-carousel';

/* Internal Files */
import general from './scripts/general.js';
import page from './scripts/sidebar-schema.js';
import blocks from './scripts/blocks/blocks.js';

document.addEventListener('DOMContentLoaded', function () {
    general()
    page()
    blocks()
});