import { getScroll } from './general';

export default function () {
    if (typeof jQuery === 'undefined') {
        return;
    }

    $(document).ready(function() {
        const $social = $('div.social');
        const $bottomFooter = $('.bottom-footer');
        const $schema = $('article');
        const $schemaFixed = $('.schema');
        const $sidebarButton = $('.sidebar-main.sidebar-button');

        var mySignupSidebar = $social.length ? $social : $bottomFooter;
        
        if (!mySignupSidebar.length || !$schema.length) {
            return;
        }

        try {
            const initialSidebarPos = mySignupSidebar.position();
            const initialSchemaPos = $schema.position();
            
            if (!initialSidebarPos || !initialSchemaPos) {
                return;
            }

            mySignupSidebar.data('position', initialSidebarPos);
            $schema.data('position', initialSchemaPos);
        } catch (e) {
            return;
        }

        let scrollHandler = function() {
            try {
                const scroll = getScroll();
                if (!scroll || typeof scroll.top !== 'number') {
                    return;
                }

                if (!mySignupSidebar.length || !$schema.length) {
                    return;
                }

                let newSidebarPos, newSchemaPos;
                try {
                    newSidebarPos = mySignupSidebar.position();
                    newSchemaPos = $schema.position();
                } catch (e) {
                    return;
                }
                
                if (!newSidebarPos || !newSchemaPos) {
                    return;
                }
                
                mySignupSidebar.data('position', newSidebarPos);
                $schema.data('position', newSchemaPos);
                
                const shPos = $schema.data('position');
                if (!shPos || typeof shPos.top !== 'number') {
                    return;
                }

                if ($schemaFixed.length) {
                    const shouldBeFixed = shPos.top < scroll.top;
                    $schemaFixed.toggleClass('fixed', shouldBeFixed);
                }

                const sPos = mySignupSidebar.data('position');
                if (!sPos || typeof sPos.top !== 'number') {
                    return;
                }

                const windowScrollTop = $(window).scrollTop();
                const windowHeight = $(window).height();
                
                if (typeof windowScrollTop !== 'number' || typeof windowHeight !== 'number') {
                    return;
                }

                if ($sidebarButton.length) {
                    const shouldBeNoFixed = sPos.top < (windowScrollTop + windowHeight);
                    $sidebarButton.toggleClass('no-fixed', shouldBeNoFixed);
                }
            } catch (e) {
                return;
            }
        };

        let throttled = false;
        $(window).scroll(function() {
            if (!throttled) {
                throttled = true;
                setTimeout(function() {
                    try {
                        scrollHandler();
                    } catch (e) {
                        // Silent error handling
                    }
                    throttled = false;
                }, 50);
            }
        });

        scrollHandler();
    });
}