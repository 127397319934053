export default function () {
    var mySideBar = $('article');
    var myHeader = $('main');
    var myFooter = $('footer.bottom-footer');

    mySideBar.data('position', mySideBar.position());
    myHeader.data('position', myHeader.position());
    myFooter.data('position', myFooter.position());

    var scrollWaiting = false;

    var GblTop;

    function GetVertOffset(srchStr) {
        GblTop = $(srchStr).offset().top;
    }

    $(function () {
        // NAVBAR
        // Define event handlers outside of the click event
        function handleSidebarToggle() {
            $('#order-now-button-sidebar').toggleClass('opened');
        }

        function handleClickOutside(e) {

            const isSidebarButton = $(e.target).hasClass('sidebar-button');
            const isNavbarButton = $(e.target).closest('.top-nav-order').length

            if (isSidebarButton) {
                return
            }

            if (isNavbarButton) {
                return
            }

            $('#order-now-button-sidebar').removeClass('opened');
        }

        function handleAccordionClick() {
            var body = $(this).next().next();
            var icon = $(this).next();
            body.slideToggle('500');
            icon.toggleClass('flipped');
        }

        $('.menu-toggle span').on('click', function () {
            $('.main-nav').slideToggle('fast');
        });

        $('.top-nav-order a').on('click', handleSidebarToggle);

        $('.sidebar-button').on('click', handleSidebarToggle);

        $('.accordion2:first-of-type i').addClass('flipped');
        $('.accordion1 .header, .accordion2 .header').on('click', handleAccordionClick);

        // Use event delegation for closing sidebar on outside click
        $('body').on('click', handleClickOutside);


        // Some other thing
        if ($('.landing-p').length > 0) {
            GetVertOffset('.landing-p');  // Sets GblTop
        }

        $(window).scroll(function () {
            if ($('.landing-p').length > 0) {
                GetVertOffset('.landing-p');
                var y = $(window).scrollTop();
                if (y >= GblTop) {
                    $('.landing-p .top-bar').addClass('fixed2 sticky-top');
                } else {
                    $('.landing-p .top-bar').removeClass('fixed2 sticky-top');
                }
            }
        });

        var myFooter = $('footer.bottom-footer');
        var priceFooter = $('.price-footer');

        // Sidebar JS
        $('.help-icon-btn').on('click', function () {
            var helpIconFocus = event.target;
            var sidebarHelpText = $('.sidebar .text');
            var helpText = $("i", this).attr('data-help');
            var helpLabel = $("i", this).attr('data-label');
            var defaultText = 'Click on the <i class="fa fa-question-circle"></i> for help';
            sidebarHelpText.html(helpText);
            $('.sidebar .label').text(helpLabel);

            sidebarHelpText.focus();

            // when user keyboard navigates from sidebar help text, return focus to the help icon that triggered the help text
            $(sidebarHelpText).focusout(function () {
                $(helpIconFocus).focus();
            });

            // Mobile help menu classes
            $('.sidebar').addClass('active');
            $('.sidebar-mobile-background').addClass('active');
        });

        // Remove active class on resize
        $(window).resize(function () {
            if ($('.sidebar').hasClass('active')) {
                $('.sidebar').removeClass('active');
                $('.sidebar-mobile-background').removeClass('active');
            }
        })

        // Close Mobile Sidebar
        $('.sidebar .close-button, .sidebar-mobile-background').click(function () {
            $('.sidebar').removeClass('active');
            $('.sidebar-mobile-background').removeClass('active');
        })

        myFooter.data('position', myFooter.position());

        priceFooter.css('width', function () {
            return $(this).outerWidth();
        })

        $(window).scroll(function () {
            if (myHeader && mySideBar && myFooter) {
                mySideBar.data('position', mySideBar.position());
                var sbPos = mySideBar.data('position');
                var scroll = getScroll();

                if (sbPos) {
                    if (sbPos.top < scroll.top) {
                        $('.sidebar.columns').addClass('fixed');
                    } else {
                        $('.sidebar.columns').removeClass('fixed');
                    }
                }

                myHeader.data('position', myHeader.position());
                myFooter.data('position', myFooter.position());

                var hPos = myHeader.data('position');
                if (hPos) {
                    if (hPos.top < scroll.top) {
                        priceFooter.addClass('fixed');
                    } else {
                        priceFooter.removeClass('fixed');
                    }
                }

                var fPos = myFooter.data('position');
                if (fPos) {
                    if (fPos !== undefined && !scrollWaiting) {
                        if ((fPos.top) < ($(window).scrollTop() + $(window).height() + priceFooter.height() + $('.bottom-footer').height())) {
                            priceFooter.addClass('no-fixed').css('width', '100%');
                            scrollWaiting = true;

                            // the timeout is to mitigate the flickering issue
                            setTimeout(function () {
                                scrollWaiting = false;
                            }, 250);
                        } else {
                            var staticWidth = priceFooter.outerWidth(); // width has to be checked before .no-fixed is removed
                            priceFooter.removeClass('no-fixed').css('width', staticWidth);
                        }
                        //console.log(waitTime);
                    }
                }
            }
        });
    });
};

export function getScroll() {
    var b = document.body;
    var e = document.documentElement;
    return {
        left: parseFloat(window.pageXOffset || b.scrollLeft || e.scrollLeft),
        top: parseFloat(window.pageYOffset || b.scrollTop || e.scrollTop),
    };
}